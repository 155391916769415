import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.is-integer";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { getAutoAssignScoreRuleList, editAutoAssignScoreConfig, removeAutoAssignScoreRule, getAutoAssignScoreRuleDetail } from '@/api/gradeRules';
export default {
  data: function data() {
    // 保留两位小数
    var checkDecemial = function checkDecemial(rule, value, callback) {
      if (!value) {
        return callback(new Error('不能为空'));
      }
      // 最多两位小数 正负都可以
      var reg = /^(^(\-)?([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
      setTimeout(function () {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (reg.test(value)) {
            callback(new Error('最多保留两位小数'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    return {
      title: '得分规则配置',
      dialogTitle: '新增得分规则',
      rule_name: '',
      tableData: [],
      dialogFormVisible: false,
      form: {
        rule_name: '',
        rating_cycle: 1,
        type: [{
          name: '',
          info: [{
            name1: '',
            name2: '',
            name3: '',
            name4: '',
            name5: 0,
            name6: ''
          }]
        }]
      },
      formLabelWidth: '140px',
      selectOption: {
        selectOption1: [{
          value: 0,
          title: '满足以下任意条件'
        }, {
          value: 1,
          title: '满足以下全部条件'
        }],
        selectOption2: [{
          value: 'orderTotal',
          title: '总成交量'
        }, {
          value: 'salesConversion',
          title: '3个月内销转率'
        }, {
          value: 'avgCallTime',
          title: '比对平均有效通话时长'
        }, {
          value: 'avgFollowAging',
          title: '比对平均跟进时效'
        }],
        selectOption3: [{
          value: 'LT',
          title: '小于<'
        }, {
          value: 'ELT',
          title: '小于等于<='
        }, {
          value: 'GT',
          title: '大于>'
        }, {
          value: 'EGT',
          title: '大于等于>='
        }],
        selectOption4: [{
          value: 0,
          title: '加'
        }
        // {
        //     value: 1,
        //     title: '减'
        // }
        ],
        selectOption5: [{
          value: 'or',
          title: '或（or）一下条件组'
        }, {
          value: 'and',
          title: '且（and）一下条件组'
        }],
        selectOption6: [{
          value: 1,
          title: '周'
        }, {
          value: 2,
          title: '月度'
        }, {
          value: 3,
          title: '季度'
        }, {
          value: 4,
          title: '年度'
        }]
      },
      // 已选择的影响因素
      selectedGroupType: ['', '', '', ''],
      // 必填校验规则
      rules: {
        rule_name: [{
          required: true,
          message: '请输入定级规则名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 50,
          message: '长度在 1 到 50 个字符',
          trigger: 'blur'
        }],
        rating_cycle: [{
          required: true,
          message: '请选择定级周期',
          trigger: 'change'
        }]
      },
      loadingSubmit: false,
      course: this.$route.query.course,
      dataId: ''
    };
  },
  mounted: function mounted() {
    this.getListFun();
  },
  computed: {},
  components: {
    Head: Head
  },
  methods: {
    // 列表查询事件
    getListFun: function getListFun(type) {
      var _this = this;
      if (type == 1) {
        // type == 1 是点击查询调用 页码需要重置
      } else {}
      getAutoAssignScoreRuleList({
        rule_name: this.rule_name
      }).then(function (respomse) {
        _this.tableData = respomse.data;
      });
    },
    // 取消 弹窗
    cancelDialogFun: function cancelDialogFun() {
      this.dialogFormVisible = false;
    },
    // 新增或者编辑弹窗
    dataMethodFun: function dataMethodFun(index, id) {
      var _this2 = this;
      var formName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'form';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs[formName].clearValidate();
      });
      this.selectedGroupType = ['', '', '', ''];
      if (index == 0) {
        // 代表是新增
        this.dataId = '';
        this.dialogTitle = '新增得分规则';
        this.form = {
          rule_name: '',
          rating_cycle: 1,
          type: [{
            name: '',
            info: [{
              name1: '',
              name2: '',
              name3: '',
              name4: '',
              name5: 0,
              name6: ''
            }]
          }]
        };
      } else {
        this.handleClick(id);
      }
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(size) {},
    handleCurrentChange: function handleCurrentChange(current) {},
    handleClick: function handleClick(id) {
      this.dialogFormVisible = true;
      this.dialogTitle = '编辑得分规则';
      this.handleInfoFun(id);
    },
    // 编辑获取信息详情
    handleInfoFun: function handleInfoFun(id) {
      var _this3 = this;
      this.dataId = id;
      getAutoAssignScoreRuleDetail({
        id: id
      }).then(function (respomse) {
        // 详情数据处理到数组中
        _this3.selectedGroupType = [];
        var data = respomse.data;
        var arr1 = [];
        var value = data.score_condition;
        for (var i in value) {
          var obj = {};
          _this3.selectedGroupType.push(i);
          // console.log('aaa', i);
          obj = {
            name: i,
            info: []
          };
          for (var j = 0; j < value[i].length; j++) {
            // console.log('bbb', value[i][j]);
            var obj1 = {
              name1: '',
              name2: '',
              name3: '',
              name4: '',
              name5: 0,
              name6: ''
            };
            var arr = [];
            for (var k in value[i][j]) {
              arr.push(k);
              arr.push(value[i][j][k]);
            }
            // console.log('arrrrrr', arr);
            for (var y = 0; y < arr.length; y++) {
              // console.log('arraaaaaaa', arr[y]);
              if (arr[y] == 'score') {
                // 判断如果缺少一个大于小于条件 就不按顺序赋值了
                obj1.name5 = 0;
                obj1.name6 = arr[y + 1];
                break;
              } else {
                if (y == 0) {
                  obj1.name1 = arr[y];
                } else if (y == 1) {
                  obj1.name2 = arr[y];
                } else if (y == 2) {
                  obj1.name3 = arr[y];
                } else if (y == 3) {
                  obj1.name4 = arr[y];
                } else if (y == 4) {
                  obj1.name5 = 0;
                } else if (y == 5) {
                  obj1.name6 = arr[y];
                }
              }
            }
            // console.log('obj1obj1obj1', obj1);
            obj.info.push(obj1);
          }
          arr1.push(obj);
        }
        // console.log('this.selectedGroupType', this.selectedGroupType);
        _this3.form = {
          rule_name: data.rule_name,
          rating_cycle: data.rating_cycle,
          type: arr1
        };
      });
    },
    // 表单提交事件
    submitForm: function submitForm(formName) {
      var _this4 = this;
      // 判断四个维度是否填写完成
      var valid = false;
      for (var i = 0; i < this.selectedGroupType.length; i++) {
        if (this.selectedGroupType[i] == '') {
          valid = true;
          break;
        }
      }
      var newSelect = this.selectedGroupType.filter(function (n) {
        return n;
      });
      if (newSelect.length < 4) {
        valid = true;
      }
      if (valid) {
        this.$message({
          message: '请检查评分维度是否完整',
          type: 'warning'
        });
        return;
      }
      // 提交之前把数据处理成后端想要的格式
      var params = {};
      var flag = false;
      try {
        this.form.type.forEach(function (val, index) {
          var arr1 = [];
          try {
            val.info.forEach(function (v, i) {
              var arr = [];
              for (var o in v) {
                arr.push(v[o]);
              }
              // console.log('arr', arr);
              var newarr = arr.filter(function (n) {
                return n;
              });
              if (newarr.length < 2) {
                flag = true;
                throw Error('请检查大于小于条件是否填写完整');
              }
              var obj = {};
              for (var k = 0; k < arr.length; k = k + 2) {
                // console.log('kk', k);
                if (k < 3) {
                  if (arr[k] == '' && arr[k + 1] == '') {} else {
                    _this4.$set(obj, arr[k], arr[k + 1]);
                  }

                  // 判断有key值必须有值
                  if (arr[k] != '' && arr[k + 1] == '' || arr[k] == '' && arr[k + 1] != '') {
                    flag = true;
                    throw Error('请检查条件是否填写完整');
                  }
                } else {
                  if (arr[k + 1] == '') {
                    flag = true;
                    break;
                  }
                  _this4.$set(obj, 'score', arr[k + 1]);
                }
              }
              if (flag) {
                throw Error('请检查分值是否填写完整');
              }
              arr1.push(obj);
            });
          } catch (e) {
            // console.log('eeeeee', e);
            _this4.$message({
              message: e,
              type: 'warning'
            });
          }
          if (flag) {
            throw Error();
          }
          // console.log('val', val);
          _this4.$set(params, val.name, arr1);
        });
      } catch (e) {}
      if (flag) {
        return;
      }
      // console.log('params', params);
      var pam = {
        rule_name: this.form.rule_name,
        rating_cycle: this.form.rating_cycle,
        score_condition: params
      };
      if (this.dataId != '') {
        pam.id = this.dataId;
      }
      console.log('aaaaaaaaa', pam);
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.loadingSubmit = true;
          editAutoAssignScoreConfig(pam).then(function (respomse) {
            console.log('respomse', respomse);
            _this4.dialogFormVisible = false;
            _this4.getListFun();
          }).finally(function () {
            _this4.loadingSubmit = false;
          });
          // 请求结束以后 loading消失 更新列表
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增条件组
    addGroupFun: function addGroupFun() {
      this.form.type.push({
        name: '',
        info: [{
          name1: '',
          name2: '',
          name3: '',
          name4: '',
          name5: 0,
          name6: ''
        }]
      });
    },
    // 删除条件组
    deleteGroupFun: function deleteGroupFun(index) {
      this.form.type.splice(index, 1);
      this.selectedGroupType.splice(index, 1);
      // console.log('000000000000', this.selectedGroupType);
    },
    // 删除定级规则
    deleteDataFun: function deleteDataFun(id) {
      var _this5 = this;
      // this.$alert('这是一段内容', '标题名称', {
      //     confirmButtonText: '确定',
      //     callback: (action) => {
      //         this.$message({
      //             type: 'info',
      //             message: `action: ${action}`
      //         });
      //     }
      // });
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        removeAutoAssignScoreRule({
          id: id
        }).then(function (respomse) {
          _this5.$message({
            message: '删除成功',
            type: 'success'
          });
          _this5.getListFun();
        });
      }).catch(function () {});
    },
    // 选择条件 新增条件组时，不再支持选择已添加的影响因素
    chooseGroupTypeFun: function chooseGroupTypeFun(val, index) {
      if (this.selectedGroupType.indexOf(val) == -1) {
        this.selectedGroupType.splice(index, 1, val);
      } else {
        this.$message({
          message: '新增条件组时，不再支持选择已添加的影响因素',
          type: 'warning'
        });
        this.$set(this.form.type[index], 'name', '');
        this.selectedGroupType.splice(index, 1, '');
      }
      // console.log('this.selectedGroupType', this.selectedGroupType);
    },
    // 新增组内循环
    addTiaojianFun: function addTiaojianFun(index, index1) {
      var obj = {
        name1: '',
        name2: '',
        name3: '',
        name4: '',
        name5: 0,
        name6: ''
      };
      this.form.type[index].info.splice(index1 + 1, 0, obj);
    },
    // 删除条件组内
    deleteTiaojianFun: function deleteTiaojianFun(index, index1) {
      this.form.type[index].info.splice(index1, 1);
    },
    // 校验数据格式
    onVaildNumberFun: function onVaildNumberFun(val, index1, index2, name) {
      // 最多四位小数 正负都可以
      var reg = /^((\-)?([1-9]{1}\d*)|^(\-)?(0{1}))(\.\d{1,4})?$/;
      if (!reg.test(val)) {
        this.$message.error('数值支持四位小数');
        this.$set(this.form.type[index1].info[index2], name, '');
      }
    }
  }
};