import request from '@/utils/request';
export function fetchList(query) {
  return request({
    url: '/article/list',
    method: 'get',
    params: query
  });
}
// 得分规则列表
export function getAutoAssignScoreRuleList(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/getAutoAssignScoreRuleList',
    method: 'post',
    data: data
  });
}

// 新建或编辑得分规则
export function editAutoAssignScoreConfig(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/editAutoAssignScoreConfig',
    method: 'post',
    data: data
  });
}

// 得分规则详情
export function getAutoAssignScoreRuleDetail(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/getAutoAssignScoreRuleDetail',
    method: 'post',
    data: data
  });
}

// 得分规则删除
export function removeAutoAssignScoreRule(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/removeAutoAssignScoreRule',
    method: 'post',
    data: data
  });
}

// 参与规则部门
export function getPromotionStructure(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/getPromotionStructure',
    method: 'post',
    data: data
  });
}
// 全部得分规则名
export function getScoreRuleNameList(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/getScoreRuleNameList',
    method: 'post',
    data: data
  });
}
// 部门&规则 列表

export function getRuleStructureMapList(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/getRuleStructureMapList',
    method: 'post',
    data: data
  });
}

// 部门&规则 新建/修改

export function editRuleStructureMap(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/editRuleStructureMap',
    method: 'post',
    data: data
  });
}

// 部门&规则 部门&规则 删除

export function removeRuleStructureMap(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/removeRuleStructureMap',
    method: 'post',
    data: data
  });
}

// 部门&规则 部门&规则 详情

export function getRuleStructureMapInfo(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/getRuleStructureMapInfo',
    method: 'post',
    data: data
  });
}

// 可配置评分规则的推广部门

export function getConfigurableStructure(data) {
  return request({
    url: '/clientserver.php/AutoAssignScoreConfig/getConfigurableStructure',
    method: 'post',
    data: data
  });
}

// 人员得分数据列表
export function getUserScoreList(data) {
  return request({
    url: '/clientserver.php/AutoAssignRankUser/getUserScoreList',
    method: 'post',
    data: data
  });
}